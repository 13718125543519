import {Component, ViewChild} from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import { CustomSpinnerService } from './core/interceptors/custom-spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'admin-app';
  @ViewChild(MatAccordion)
  accordion: MatAccordion = new MatAccordion;
  showCustomLoader: number = 0;

  constructor(
    private spin:CustomSpinnerService, 
  ) { }

  ngOnInit() {
    this.spin.counter.subscribe(x => { setTimeout(()=> {this.showCustomLoader = x;}, 10);});
  }
}
